import {Component, OnInit} from '@angular/core';
import { faBrain } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
faBrain = faBrain;
  constructor() {
  }

  ngOnInit() {
  }

  login() {

  }

}
